function collapsedContent() {
    const collapse = document.getElementsByClassName('collapsible');
    let i;

    if (collapse) {
        for (i = 0; i < collapse.length; i++) {
            collapse[i].addEventListener('click', function () {
                this.classList.toggle('active');
                var content = this.nextElementSibling;
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + 'px';
                }
            })
        }
    }
}

export default function init() {
    collapsedContent();
}