export default function init() {
    var form = document.querySelector('.in-content-feedback-form');

    if ( !form ) {
        return; // Exit the function if form is undefined
    }

    var feedbackWrapper = form.querySelector('.feedback-wrapper');
    var yesButton = form.querySelector('.button-feedback[data-value="Yes"]');
    var noButton = form.querySelector('.button-feedback[data-value="No"]');
    var yesFeedback = form.querySelector('.feedback-block.helpful-yes');
    var noFeedback = form.querySelector('.feedback-block.helpful-no');
    var feedbackTextarea = form.querySelector('textarea');
    var submitButton = form.querySelector('input[type="submit"]');
    var yesNoLabel = form.querySelector('.yes-no-label');
    var yesNoInput = form.querySelector('input.yes-no-feedback');

    function handleFeedback(value) {
        yesNoInput.value = value;
        yesNoLabel.style.display = 'none';
        form.querySelector('.buttons').style.display = 'none';
        yesFeedback.style.display = value === 'Yes' ? 'block' : 'none';
        noFeedback.style.display = value === 'No' ? 'block' : 'none';
        feedbackTextarea.style.display = 'block';
        submitButton.style.display = 'inline-block';
    }

    yesButton.addEventListener('click', function() {
        handleFeedback('Yes');
    });

    noButton.addEventListener('click', function() {
        handleFeedback('No');
    });

    form.parentElement.addEventListener('wpcf7mailsent', function() {
        feedbackWrapper.style.display = 'none';
    }, false);
}