import inContentFeedback from './components/in-content-feedback'
import collapsedContent from './components/collapsable-content'

(function ($) {

    'use strict';

    $(function () {

        //all hash links smooth scroll
        $(document).on('click', 'body a[href^="#"]:not(.menu-button):not(#search-icon):not(.show-more):not(.accordion-title):not([data-target="#consumer-disclosure"]):not(.hearing-cta):not(.back-btn)', function (event) {

            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href') + ',[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top - 50,
            }, 500);
        });

        setTimeout(function(){

            $('table').each(function() {

                $(this).removeAttr('rules');
                $(this).attr('border', 0);

                if (!$(this).parent('.table-container').length) {

                    $(this).wrapAll('<div class="table-container"></div>');

                    //$(this).parent().after('<i class="table-info">Please scroll right for more information.</i>');

                }

                $(this).find('td:first-child, th:first-child').each(function() {

                    if ($(this).is(':empty')) {

                        $(this).html('<span>&nbsp;</span>');

                    }
                    else {

                        if ($(this).children('strong').length === 0 && $(this).children('span').length === 0)
                            $(this).wrapInner('<span />');

                    }

                });

            });

        }, 300);

        if ($('.faqs-list-container').length)
        {

            var acc = document.getElementsByClassName('question');
            var i;

            for (i = 0; i < acc.length; i++) {

                acc[i].addEventListener('click', function() {

                    this.classList.toggle('active');

                    var panel = this.nextElementSibling;

                    if (panel.style.maxHeight) {

                        panel.style.maxHeight = null;

                    }
                    else {

                        panel.style.maxHeight = panel.scrollHeight + 'px';

                    }


                });

            }

        }

        if ($('.faq-container').length) {

            $('.accordion > .accordion-content').hide();

            $('.accordion .accordion-title').on('click', function(e) {

                e.preventDefault();

                var $this = $(this);

                if ($this.parent().hasClass('is-active')) {

                    $this.parent().removeClass('is-active');
                    $this.next().slideUp(350);

                }
                else {

                    $this.parent().parent().find('li').removeClass('is-active');
                    $this.parent().parent().find('li .accordion-content').slideUp(350);
                    $this.parent().addClass('is-active');
                    $this.next().slideToggle(350);

                }

            });

        }

        if ($('.helpful-block-container').length && $('.citation-shell').length) {

            $('.citation-shell').insertBefore($('.helpful-block-container'));
        }

        // Set passive listeners to events
        jQuery.event.special.touchstart = {
            setup: function( _, ns, handle ) {
                this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
            },
        };
        jQuery.event.special.touchmove = {
            setup: function( _, ns, handle ) {
                this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
            },
        };
        jQuery.event.special.wheel = {
            setup: function( _, ns, handle ){
                this.addEventListener('wheel', handle, { passive: true });
            },
        };
        jQuery.event.special.mousewheel = {
            setup: function( _, ns, handle ){
                this.addEventListener('mousewheel', handle, { passive: true });
            },
        };

        inContentFeedback();
        collapsedContent();

    });

})(jQuery);

